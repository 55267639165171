<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/clientes" />
        </v-col>
      </v-row>
    </v-card-actions>
    <contrato-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      :contrato="contrato"
      @salvar="salvar"
    />
  </v-container>
</template>
<script>
import contratos from "@/api/contratos";
import formatarData from "@/utils/formatar-data-input";

export default {
  components: {
    ContratoCampos: () => import("./ContratoCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      contrato: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await contratos.buscar(this.$route.params.contrato);
        var contrato = {
          inicio: resposta.data.data.inicio,
          termino: resposta.data.data.termino,
          area_id: resposta.data.data.area_id,
          cliente_id: resposta.data.data.cliente_id,
          dia_vencimento: resposta.data.data.dia_vencimento,
          valor: resposta.data.data.valor,
          vagas: resposta.data.data.vagas,
          ativo: resposta.data.data.ativo,
          isento: resposta.data.data.isento,
          tipo_pagamento_id: resposta.data.data.tipo_pagamento_id,
          tipo_cobranca: resposta.data.data.tipo_cobranca,
        };

        this.contrato = contrato;
      } catch (e) {
        this.$router.push(
          {
            name: "Contratos do Cliente",
            params: { cliente: this.$route.params.cliente },
          },
          () =>
            this.$snackbar.mostrar({
              cor: "error",
              mensagem: this.$erroApi._(e),
            })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(contrato) {
      this.carregando = true;
      this.validacaoFormulario = {};
      contrato.id = this.$route.params.contrato;
      contrato.inicio = formatarData.api(contrato.inicio);
      contrato.termino = formatarData.api(contrato.termino);

      console.log(contrato);

      try {
        await contratos.atualizar(contrato.id, contrato);

        this.$router.push(
          {
            name: "Contratos do Cliente",
            params: { cliente: this.$route.params.cliente },
          },
          () =>
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: this.$mensagens._("sucesso_editar"),
            })
        );
      } catch (e) {
        if (e.response.status === 422) {
          console.log(e.response);
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
